import React from "react";
import project2 from "../../assets/images/projects/project2.jpg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const COOP = () => {
	const { t } = useTranslation();

	return (
		<section className="services-details-area pt-100px">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12">
						<div className="services-details-desc">
							<span className="sub-title">
								<h2>{t("home.COOPh2")}</h2>
							</span>

							<h3>{t("home.COOPh31")}</h3>
							<p>{t("home.COOPp1")}</p>
							<div className="row align-items-center">
								<div className="col-lg-5 col-md-5">
									<div className="image">
										<img src={project2} alt="about" />
									</div>
								</div>

								<div className="col-lg-6 col-md-6">
									<div className="content">
										<h3>{t("home.COOPh32")}</h3>
										<p>{t("home.COOPp2")}</p>
									</div>

									<p>{t("home.COOPp3")}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default COOP;
